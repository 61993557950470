import { Accordion, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import Conditional from "../../components/Conditional";
import { CartItem, YardProduct } from "../../typing/gql.schema";
import useCategoryVisibility from "../hooks/useCategoryVisibility";
import useEditModalState from "../hooks/useEditModalState";
import AutoBidCategoryItem from "./AutoBidCategoryItem";
import EditAutoBidItemModal from "./EditAutoBidItemModal";
import TableWrapper from "./TableWrapper";

type Props = {
  cartItems: Array<CartItem>;
  pastProductsForRfq: Array<YardProduct>;
  refetchBidData: () => Promise<void>;
};

const AutoBidCategory = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const editModal = useEditModalState();
  const visibility = useCategoryVisibility("auto");
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Sort cartItems alphabetically by plant.latinName
  const sortedCartItems = [...props.cartItems].sort((a, b) =>
    a.plant.latinName.localeCompare(b.plant.latinName)
  );

  return (
    <>
      <Accordion value={visibility.value} onChange={visibility.setValue} variant="contained" data-testid="category">
        <Accordion.Item value={"auto"}>
          <Accordion.Control>
            {t("autoBidCategoryText1")} (
            <Text c="green" component="a" td="underline" variant="link" onClick={() => navigate("/inventory")}>
              {t("autoBidCategoryText2")}
            </Text>
            )
          </Accordion.Control>
          <Accordion.Panel>
            <Conditional renderIf={!isMobile}>
              <TableWrapper>
                {sortedCartItems.map((item, index) => (
                  <React.Fragment key={item.plant.latinName + index}>
                    <AutoBidCategoryItem
                      key={item.plant.latinName + index}
                      item={item}
                      onEdit={editModal.open}
                      pastProductsForRfq={props.pastProductsForRfq}
                    />
                  </React.Fragment>
                ))}
              </TableWrapper>
            </Conditional>
            <Conditional renderIf={isMobile}>
              {sortedCartItems.map((item, index) => (
                <React.Fragment key={item.plant.latinName + index}>
                  <AutoBidCategoryItem
                    key={item.plant.latinName + index}
                    item={item}
                    onEdit={editModal.open}
                    pastProductsForRfq={props.pastProductsForRfq}
                  />
                </React.Fragment>
              ))}
            </Conditional>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <EditAutoBidItemModal {...editModal} />
    </>
  );
};

export default AutoBidCategory;
